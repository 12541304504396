import React, { useEffect, useState, useRef } from "react";
import { useForm } from 'react-hook-form';
import { MdOutlineDoneAll } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { InStockP, DoneDiv, EditProductButton, EditImgInput, EditProductDiv, EditProductInput, ProductsDiv, ProductsInfoDiv, SortSpan, SortDiv, SearchProducts, SearchDivIconDiv, SelectDiv } from "./styled";
import { FaEdit } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import { FaSearch } from "react-icons/fa";

export const MyProductsList = React.memo(() => {
  const { register, handleSubmit } = useForm();
  const itemsPerPage = 20;
  const [products, setProducts] = useState([]);
  const [editProductIndex, setEditProductIndex] = useState(null);
  const [sortProducts, setSortProducts] = useState("minMax");
  const [sortProductsBoolean, setSortProductsBoolean] = useState(true);
  const [visibleItems, setVisibleItems] = useState(itemsPerPage);
  const [searchProducts, setSerachProducts] = useState('')

  const containerRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const req = await fetch("https://www.server.mayyanart.com/products")
        const res = await req.json();
        setProducts(res);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();

  }, [editProductIndex]);

  const deleteProduct = async (i) => {
    try {
      const ProductsToDelete = products[i];
      const req = await fetch(`https://www.server.mayyanart.com/product/${ProductsToDelete._id}`, {
        method: "DELETE",
      });

      if (req.ok) {
        const updatedproducts = products.filter((_, index) => index !== i);
        setProducts(updatedproducts);
        setEditProductIndex(null);
      } else {
        console.error("Не удалось удалить обзор");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const [instock , setinstock] = useState(false)
  const [showBg , setShowBg] = useState(false)
  const changeProduct = async (data, i) => {
    try {
      const formData = new FormData();

      formData.append('titleRU', data?.titleRU?.trim() !== '' ? data.titleRU : products[i].titleRU);
      formData.append('titleEN', data?.titleEN?.trim() !== '' ? data.titleEN : products[i].titleEN);
      formData.append('titleAM', data?.titleAM?.trim() !== '' ? data.titleAM : products[i].titleAM);
      formData.append('ktavRU', data?.ktavRU?.trim() !== '' ? data.ktavRU : products[i].ktavRU);
      formData.append('ktavEN', data?.ktavEN?.trim() !== '' ? data.ktavEN : products[i].ktavEN);
      formData.append('ktavAM', data?.ktavAM?.trim() !== '' ? data.ktavAM : products[i].ktavAM);
      formData.append('yuxanerkRU', data?.yuxanerkRU?.trim() !== '' ? data.yuxanerkRU : products[i].yuxanerkRU);
      formData.append('yuxanerkEN', data?.yuxanerkEN?.trim() !== '' ? data.yuxanerkEN : products[i].yuxanerkEN);
      formData.append('yuxanerAMk', data?.yuxanerkAM?.trim() !== '' ? data.yuxanerkAM : products[i].yuxanerkAM);
      formData.append('size', data?.size?.trim() !== '' ? data.size : products[i].size);
      formData.append('timer', data?.timer?.trim() !== '' ? data.timer : products[i].timer);
      formData.append('descriptionRU', data?.descriptionRU?.trim() !== '' ? data.descriptionRU : products[i].descriptionRU);
      formData.append('descriptionEN', data?.descriptionEN?.trim() !== '' ? data.descriptionEN : products[i].descriptionEN);
      formData.append('descriptionAM', data?.descriptionAM?.trim() !== '' ? data.descriptionAM : products[i].descriptionAM);
      formData.append('department', data?.department?.trim() !== '' ? data.department : products[i].department);
      formData.append('instock', instock  ? data.instock : products[i].instock);
      formData.append('bg', showBg  ? data.bg : products[i].bg);
      console.log(data.instock);
      if (data.img instanceof FileList && data.img.length > 0) {
        for (let i = 0; i < data.img.length; i++) {
          const image = data.img[i];
          formData.append('img', image);
        }
      }
      const req = await fetch(`https://www.server.mayyanart.com/product/${products[i]._id}`, {
        method: "PUT",
        body: formData,
      });

      if (req.ok) {
        const updatedProducts = [...products];
        updatedProducts[i] = { ...updatedProducts[i], ...data, img: null };
        setProducts(updatedProducts);
        setEditProductIndex(null);
      } else {
        console.error("Не удалось обновить продукт");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const toggleEditProduct = (index) => {
    setEditProductIndex((e) => (e === index ? null : index));
  };

  const handleSort = () => {
    const sortedProducts = [...products];

    if (sortProducts === 'minMax') {
      sortedProducts.sort((a, b) => a.price - b.price);
      setSortProducts('maxMin');
    } else {
      sortedProducts.sort((a, b) => b.price - a.price);
      setSortProducts('minMax');

    }

    setProducts(sortedProducts);
  };
  const handleSortBoolean = () => {
    const sortedProducts = [...products];

    sortedProducts.sort((a, b) => {
      setSortProductsBoolean((e) => !e)
      const aValue = a.inStock ? 0 : 1;
      const bValue = b.inStock ? 0 : 1;
      return (aValue - bValue);
    });

    setSortProductsBoolean(!sortProductsBoolean);
    if (!sortProductsBoolean) {
      sortedProducts.reverse();
    }

    setProducts(sortedProducts);
  };


  const handleScroll = () => {
    const container = containerRef.current;
    if (container) {
      const isAtBottom =
        container.scrollTop + container.clientHeight >= container.scrollHeight - 20;


      if (isAtBottom) {
        setVisibleItems((prevVisibleItems) => prevVisibleItems + itemsPerPage);
      }
    }
  };

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);
  return (
    <>

      <ProductsDiv ref={containerRef}>
        <SortDiv>
          <SearchProducts placeholder='search' onChange={(e) => setSerachProducts(e.target.value)} />
          <SearchDivIconDiv>
            <FaSearch />
          </SearchDivIconDiv>
        </SortDiv>

        {products.filter(

          (e) =>
            e.titleRU?.toLowerCase().includes(searchProducts.toLowerCase()) ||
            e.titleAM?.toLowerCase().includes(searchProducts.toLowerCase()) ||
            e.titleEN?.toLowerCase().includes(searchProducts.toLowerCase()) ||
            e.descriptionRU?.toLowerCase().includes(searchProducts.toLowerCase()) ||
            e.descriptionEN?.toLowerCase().includes(searchProducts.toLowerCase()) ||
            e.descriptionAM?.toLowerCase().includes(searchProducts.toLowerCase())
        ).slice(0, visibleItems)?.map((e, i) => (

          <ProductsInfoDiv key={e._id}>
            <DoneDiv>
              <button onClick={() => toggleEditProduct(i)}>{<FaEdit />} </button>
              <span> </span>
              <button onClick={() => deleteProduct(i)}> {<RxCross1 />}</button>
            </DoneDiv>
            <p>title RU: {e.titleRU}</p>
            <p>title EN: {e.titleEN}</p>
            <p>title AM: {e.titleAM}</p>
            <p>img: {e.imgs.map((a, i) => <p>{i + 1 + ")"} <a href={`https://www.server.mayyanart.com/uploads/${a}`} target="_blank">{a}</a></p>)}</p>
            <p>ktav RU: {e.ktavRU}</p>
            <p>ktav EN: {e.ktavEN}</p>
            <p>ktav AM: {e.ktavAM}</p>
            <p>size: {e.size}</p>
            <p>timer: {e.timer}</p>
            <p>yuxanerk RU: {e.yuxanerkRU}</p>
            <p>yuxanerk EN: {e.yuxanerkEN}</p>
            <p>yuxanerk AM: {e.yuxanerkAM}</p>
            <p>discription RU: {e.descriptionRU}</p>
            <p>discription EN: {e.descriptionEN}</p>
            <p>discription AM: {e.descriptionAM}</p>
            <p>department: {e.department}</p>
            <p>Կարգավիճակ: {e.instock ? <span style={{ color: "green" }}>Ցուցադրվում է</span > : <span style={{ color: "red" }}>Չի ցուցադրվում</span>}</p>
            <p>Գլխաոր նկարի ֆոն: {e.bg ? <span style={{ color: "green" }}>Ցուցադրվում է</span > : <span style={{ color: "red" }}>Չի ցուցադրվում</span>}</p>

            {editProductIndex === i && (
              <EditProductDiv onSubmit={handleSubmit((data) => changeProduct(data, i))}>
                <EditProductInput placeholder="title RU"  {...register("titleRU")} /><br />
                <EditProductInput placeholder="title EN"  {...register("titleEN")} /><br />
                <EditProductInput placeholder="title AM"  {...register("titleAM")} /><br />
                <EditProductInput placeholder="ktav RU"   {...register('ktavRU')} /><br />
                <EditProductInput placeholder="ktav EN"   {...register('ktavEN')} /><br />
                <EditProductInput placeholder="ktav AM"   {...register('ktavAM')} /><br />
                <EditProductInput placeholder="yuxanerk RU"   {...register('yuxanerkRU')} /><br />
                <EditProductInput placeholder="yuxanerk EN"   {...register('yuxanerkEN')} /><br />
                <EditProductInput placeholder="yuxanerk AM"   {...register('yuxanerkAM')} /><br />
                <EditProductInput placeholder="size"   {...register('size')} /><br />
                <EditProductInput placeholder="timer 2024-01-21"   {...register('timer')} /><br />
                <EditProductInput placeholder="description RU"   {...register('descriptionRU')} /><br />
                <EditProductInput placeholder="description EN"   {...register('descriptionEN')} /><br />
                <EditProductInput placeholder="description AM"   {...register('descriptionAM')} /><br />
                <EditProductInput type="file"  accept='image/*' multiple {...register('img')} style={{marginTop:"20px"}}/><br />
                
                <div onClick={() => setinstock(e => true)} style={{marginTop:"20px"}}><label><input type="checkbox" defaultChecked={products[i].instock} {...register('instock')}/><span >Հասանելի է</span></label></div>
                <div onClick={() => setShowBg(e => true)} style={{marginTop:"20px"}}><label><input type="checkbox" defaultChecked={products[i].bg} {...register('bg')}/><span >Ցույց տալ հետևի ֆոնը</span></label></div>
                <p><SelectDiv {...register('department')}>
                  <option>
                    Paintings
                  </option>
                  <option>
                    Wall art
                  </option>
                  <option>
                    NFT
                  </option>
                  <option>
                  Daily scathes
                  </option>
                </SelectDiv></p>
                <EditProductButton  style={{marginTop:"20px"}}>Փոփոխել</EditProductButton>
              </EditProductDiv>
            )}
            <hr />

          </ProductsInfoDiv>

        ))}

      </ProductsDiv>
    </>
  );
});
