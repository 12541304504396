import React, { useState } from "react";
import { useForm } from "react-hook-form";

import {
  RamkaDiv,
  TitleDiv,
  AddProductFormDiv,
  AddProductsInput,
  AddProductsInputCheck,
  AddImgInput,
  AddProductTextArea,
  AddProductsButton,
  AddProductsSelect,
} from "./styled"
import { toast } from "react-toastify";

export const AddHome = () => {
  const { register: productsRegister, handleSubmit: handleProductsSubmit } = useForm();
  const [waitRequest, setWaitRequest] = useState(false)
  const [loading, setLoading] = useState(true)
  const onProductsSubmit = async (data) => {
    if (!data.img[0]) {
      return
    }
    try {
      setLoading(false)
      const formData = new FormData();
      formData.append('src', data.img[0]);
      formData.append('textAM', data.textAM);
      formData.append('textTitleAM', data.textTitleAM);
      formData.append('textRU', data.textRU);
      formData.append('textTitleRU', data.textTitleRU);
      formData.append('textEN', data.textEN);
      formData.append('textTitleEN', data.textTitleEN);
      setWaitRequest(true)
      const req = await fetch('https://www.server.mayyanart.com/home-post', {
        method: 'POST',
        body: formData,
      });
      setLoading(true)
      setWaitRequest(false)
      const res = req.json()
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <RamkaDiv>

      <TitleDiv>
        <h3>Ավելացնել նկար</h3>
      </TitleDiv>
      <AddProductFormDiv onSubmit={handleProductsSubmit(onProductsSubmit)}>

        <AddProductsInput placeholder='Տեքստի վերնագիր AM' {...productsRegister('textTitleAM')} />
        <AddProductsInput placeholder='Տեքստ AM' {...productsRegister('textAM')} />
        <AddProductsInput placeholder='Տեքստի վերնագիր RU' {...productsRegister('textTitleRU')} />
        <AddProductsInput placeholder='Տեքստ RU' {...productsRegister('textRU')} />
        <AddProductsInput placeholder='Տեքստի վերնագիր EN' {...productsRegister('textTitleEN')} />
        <AddProductsInput placeholder='Տեքստ EN' {...productsRegister('textEN')} />

        <AddImgInput type='file'  accept='image/*'  {...productsRegister('img')} />

        

        <AddProductsButton disabled={!loading}>{loading ? "Ավելացնել նկար" : "սպասել..."}</AddProductsButton>
      </AddProductFormDiv>
    </RamkaDiv>
  )

}