import React, { useEffect, useState } from "react";
import { Activ, DepName, DepartmentDiv, HrefInput, Name, NameDivs, RadioDiv, RamkaDiv, SaveBut } from "./styled";
import { toast } from "react-toastify";


export const ChangeNetwork = () => {
    const [defaultValue, setDefaultValue] = useState()
    const [loading, setLoading] = useState(true)
    const [text, setText] = useState();

    useEffect(() => {
        let obj = {
            tt: defaultValue?.tt[0].href ? defaultValue?.tt[0].href : "",
            tg: defaultValue?.tg[0].href ? defaultValue?.tg[0].href : "",
            yt: defaultValue?.yt[0].href ? defaultValue?.yt[0].href : "",
            fb: defaultValue?.fb[0].href ? defaultValue?.fb[0].href : "",
            ld: defaultValue?.ld[0].href ? defaultValue?.ld[0].href : "",
            tw: defaultValue?.tw[0].href ? defaultValue?.tw[0].href : "",
            inst: defaultValue?.inst[0].href ? defaultValue?.inst[0].href : "",
        }
        setText(obj)
    } , [defaultValue])


    const myOnChange = (e) => {
        const { name, value } = e.target;
        setText((prev) => {
            return { ...prev, [name]: value };
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const req = await fetch("https://www.server.mayyanart.com/network-get")
                const res = await req.json()
                console.log(res.dep);
                setDefaultValue(res.dep)
            } catch (error) {
                console.log(error);
            }
        }
        fetchData()

    }, [])
    const [radioValues, setRadioValues] = useState()
    useEffect(() => {
        const obj = [
            { name: "tt", activ: defaultValue?.tt[0].activ ? true : false },
            { name: "tg", activ: defaultValue?.tg[0].activ ? true : false },
            { name: "yt", activ: defaultValue?.yt[0].activ ? true : false },
            { name: "fb", activ: defaultValue?.fb[0].activ ? true : false },
            { name: "ld", activ: defaultValue?.ld[0].activ ? true : false },
            { name: "tw", activ: defaultValue?.tw[0].activ ? true : false },
            { name: "inst", activ: defaultValue?.inst[0].activ ? true : false },
        ];
        setRadioValues(obj)
    }, [defaultValue])






    const changeActiv = (index) => {
        setRadioValues(prevState => {
            const updatedRadioValues = [...prevState];
            updatedRadioValues[index] = {
                ...updatedRadioValues[index],
                activ: !updatedRadioValues[index].activ
            };
            return updatedRadioValues;
        });
    };

    const fetchData = async () => {
        setLoading(false)

        const obj = {
            tt: radioValues[0].activ,
            tg: radioValues[1].activ,
            yt: radioValues[2].activ,
            fb: radioValues[3].activ,
            ld: radioValues[4].activ,
            tw: radioValues[5].activ,
            inst: radioValues[6].activ,
            ttHref: text?.tt ? text?.tt : defaultValue.tt[0].href,
            tgHref: text?.tg ? text?.tg : defaultValue.tg[0].href,
            ytHref: text?.yt ? text?.yt : defaultValue.yt[0].href,
            fbHref: text?.fb ? text?.fb : defaultValue.fb[0].href,
            ldHref: text?.ld ? text?.ld : defaultValue.ld[0].href,
            twHref: text?.tw ? text?.tw : defaultValue.tw[0].href,
            instHref: text?.inst ? text?.inst : defaultValue.inst[0].href,

        }
        try {
            const req = await fetch("https://www.server.mayyanart.com/network-put", {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(obj)
            });

            setLoading(true)
        } catch (error) {
            console.log(error);
        }

    }
    const objName = [
        { name: "TikTok" },
        { name: "Telegram" },
        { name: "youtube" },
        { name: "Fackebook" },
        { name: "Linkedin" },
        { name: "Twitter" },
        { name: "Instagram" },
    ]
    return (
        <RamkaDiv>
            <DepartmentDiv>
                {radioValues?.map((e, i) => {
                    return (
                        <NameDivs>
                            <Name>{objName[i].name}</Name> <Activ style={{ color: e.activ ? "green" : "red" }} onClick={() => changeActiv(i)}>{e.activ ? "Բաց է" : "Փակ է"}</Activ>
                        </NameDivs>
                    )
                })}
                <HrefInput placeholder="TikTok" name="tt" value={text?.tt} onChange={myOnChange} />
                <HrefInput placeholder="Telegram" name="tg" value={text?.tg} onChange={myOnChange} />
                <HrefInput placeholder="youtube" name="yt" value={text?.yt} onChange={myOnChange} />
                <HrefInput placeholder="Fackebook" name="fb" value={text?.fb} onChange={myOnChange} />
                <HrefInput placeholder="Linkedin" name="ld" value={text?.ld} onChange={myOnChange} />
                <HrefInput placeholder="Twitter" name="tw" value={text?.tw} onChange={myOnChange} />
                <HrefInput placeholder="Instagram" name="inst" value={text?.inst} onChange={myOnChange} />

            </DepartmentDiv>
            <SaveBut onClick={fetchData}>{loading ? "Փոփոխել" : "Սպասեք"}</SaveBut>
        </RamkaDiv>
    );
};
