import React , {useState} from "react";
import { useForm } from "react-hook-form";
import { AddUserFormDiv, AddUserInputDiv, AddUsersInput, ButtonInForm, CheckInput } from "./styled";
import { toast } from "react-toastify";

export const AddAdmin = () => {
    const { register, handleSubmit } = useForm();
    const [waitRequest , setWaitRequest] = useState(false)

    const changeUsers = async (data, i) => {
        try {
            setWaitRequest(true)
            const formData = new FormData();
            formData.append('login', data.login);
            formData.append('password', data.password);

            const req = await fetch("https://www.server.mayyanart.com/admin/add-admin", {
                method: "POST",
                body:formData,
            });
            setWaitRequest(false)

            
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <>
            <AddUserFormDiv onSubmit={handleSubmit((data) => changeUsers(data))}>
                <h3>Ավելացնել ադմին</h3>
                <AddUserInputDiv>
                    <AddUsersInput placeholder="login" {...register('login')} />
                    <AddUsersInput placeholder="password" {...register('password')} />
                    <ButtonInForm disabled={waitRequest} >{!waitRequest ? "add user" : "loading..."}</ButtonInForm>
                </AddUserInputDiv>
            </AddUserFormDiv>
        </>
    )
}