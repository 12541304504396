import React, { useState, useEffect } from "react";
import Carousel from "react-elastic-carousel";
import {
  Desc,
  Item,
  NameWriter,
  Position,
  Stars,
  ReviewTextarea,
  ReviewInput,
  TitleReviews,
  RamkaDiv,
  ReviewButton,
  StarSpan,
  Star,
  BGCdiv,
} from "./styled";
import { FaStar } from "react-icons/fa";
import "./styles.css";
import { toast } from "react-toastify";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 3 },
];

export const RevComp = () => {
  const [reviews, setReviews] = useState([]);
  const [show, setShow] = useState(false);
  const starsArr = [1, 2, 3, 4, 5];
  const [stars, setStars] = useState(0);
  const [addreviesw, setAddReviews] = useState(false);
  const [text, setText] = useState({
    name: "",
    disc: "",
    position: "",
    stars: "",
  });
  const myOnChange = (e) => {
    const { name, value } = e.target;
    setText((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const addreview = async () => {
    try {
      text.stars = stars;

      if (!text.disc) {
        return toast.error("Поле 'отзыв' не может быть пустым");
      }
      if (!text.position) {
        return toast.error("Поле 'профессия' не может быть пустым!");
      }
      if (!text.name) {
        return toast.error("Поле 'Имя' не может быть пустым!");
      }
      if (stars === 0) {
        return toast.error("Поставьте вашу оценку");
      }
      const req = await fetch("https://www.server.mayyanart.com/reviews", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(text),
      });
      if (req.ok) {
        toast.success("Ваш отзыв отправлен на модерацию!");
        setText({
          disc: "",
          position: "",
          stars: "",
          name: "",
        });
      } else return toast.error("Ошибка при отправление отзыва");
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const request = await fetch("https://www.server.mayyanart.com/reviews");
        const response = await request.json();
        setReviews(response);
        console.log(response);
        console.log(request);
      } catch (err) {
        console.log(err);
      }
    };

    fetchReviews();
  }, []);

  return (
    <>
      {addreviesw && (
        <RamkaDiv>
          <TitleReviews>Добавить свой отзыв на сайте</TitleReviews>
          <ReviewInput
            placeholder="Ваша профессия"
            name="position"
            value={text.position}
            onChange={myOnChange}
          />
          <ReviewInput
            placeholder="Ваша Имя"
            name="name"
            value={text.name}
            onChange={myOnChange}
          />
          <ReviewTextarea
            placeholder="Ваш отзыв"
            maxLength={300}
            name="disc"
            value={text.disc}
            onChange={myOnChange}
          />

          <Star>
            {starsArr.map((e, i) => {
              let count = i + 1;
              return (
                <StarSpan key={i + e}>
                  <FaStar
                    onClick={() => setStars(count)}
                    color={count <= stars ? "yellow" : "gray"}
                  />
                </StarSpan>
              );
            })}
          </Star>
          <ReviewButton onClick={addreview}>Добавить отзыв</ReviewButton>
        </RamkaDiv>
      )}
      {addreviesw && <BGCdiv onClick={() => setAddReviews(false)} />}
      <h1 style={{ textAlign: "center", fontSize: "55px" }}>
        Read our clients' opinions
      </h1>

      <div className="App">
        <Carousel breakPoints={breakPoints}>
          {reviews?.map((element, index) => {
            return (
              <Item key={element + index}>
                <NameWriter>
                  {element.name} {element.lastName}
                </NameWriter>
                <Position>{element.position}</Position>
                <Stars>
                  {starsArr.map((e, i) => {
                    if (e <= element.stars) {
                      return <FaStar key={e + i}/>;
                    }
                  })}
                </Stars>
                <Desc>{element.disc}</Desc>
              </Item>
            );
          })}
        </Carousel>
        <div className="butDiv">
          <h3>Add review</h3>
          <ReviewButton onClick={() => setAddReviews(true)}>
            Добавить отзыв
          </ReviewButton>
        </div>
      </div>
    </>
  );
};
