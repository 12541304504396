import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import {
  AdminLoginDiv,
  AdminLoginForm,
  AdminPanelButton,
  AdminPanelInput,
  AdminDiv,
  AdminHeader,
  AdminHeaderProfileImg,
  AdminBody,
  AdminBodyDivsAll,
  AdminBodyDivs,
  AdminBodyDiv,
  AdminBodyDivImgDiv,
  AdminBodyImg,
  RamkaBgcDiv,
  AdminBodyTextDiv,
  AdminBodyText,
} from "./styled";
import adminImg from "./assets/admin.png";
import user from "./assets/user.png";
import users from "./assets/users.png";
import products from "./assets/products.png";
import order from "./assets/order.png";
import category from "./assets/category.png";
import review from "./assets/review.png";
import { AddProducts } from "./addProduct";
import { CloseDiv } from "./styled";
import { MyProductsList } from "./productList";
import { ChangeDep } from "./changeDep";
import { ChangeNetwork } from "./changeNetwork";
import { AddHome } from "./addHome";
import { MyHomeList } from "./homeList";
import { AddAbout } from "./addAbout";
import { MyAboutList } from "./aboutList";
import { AddBGC } from "./addBGC";
import { MyBGCList } from "./BGCList";
import { AddAdmin } from "./addAdmin";
import { AllAdmins } from "./users";

const AdminPanel = () => {
  const [openReviews, setOpenReviews] = useState(false);
  const [adminLogin, setAdminLogin] = useState(false)
  const [openAddMenu, setOpenAddMenu] = useState({
    addProduct: false,
    review: false,
    products: false,
    users: false,
    addUser: false,
    changeDep: false,
    changeNetwork: false,
    addHome: false,
    changeHome: false,
    addAbout: false,
    changeAbout: false,
    addBGC: false,
    bgcList: false,
    addAdmin: false,
    adminList: false
  });
  const navigate = useNavigate();
  const { register: loginRegister, handleSubmit: handleLoginSubmit } =
    useForm();

  const onLoginSubmit = async (data) => {
    try {
      const req = await fetch("https://www.server.mayyanart.com/admin/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const res = await req.json();
      console.log(res);
      if (res.token) {
        setAdminLogin(true)
        navigate("/admin");
      } else {
        alert("սխալ լոգին կամ գաղտնաբառ");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const openAddProduct = () => {
    setOpenAddMenu({ ...openAddMenu, addProduct: true });
  };
  const closeAddProductMenu = () => {
    setOpenAddMenu({
      ...openAddMenu,
      addProduct: false,
      review: false,
      products: false,
      users: false,
      addUser: false,
      changeDep: false,
      changeNetwork: false,
      changeHome: false,
      addHome: false,
      addAbout: false,
      changeAbout: false,
      bgcList: false,
      addBGC: false,
      addAdmin: false,
    adminList: false

    });
    setOpenReviews(false);
  };


  const openProductsMenu = () => {
    setOpenAddMenu({ ...openAddMenu, products: true });
  };
  const openHomeList = () => {
    setOpenAddMenu({ ...openAddMenu, changeHome: true });
  };

  const openChangeDepMenu = () => {
    setOpenAddMenu({ ...openAddMenu, changeDep: true });
  };
  const openChangeNetworkMenu = () => {
    setOpenAddMenu({ ...openAddMenu, changeNetwork: true });
  };
  const addHome = () => {
    setOpenAddMenu({ ...openAddMenu, addHome: true });
  };
  const openAddAbout = () => {
    setOpenAddMenu({ ...openAddMenu, addAbout: true });
  };
  const openAboutChange = () => {
    setOpenAddMenu({ ...openAddMenu, changeAbout: true });
  };
  const openAddBGC = () => {
    setOpenAddMenu({ ...openAddMenu, addBGC: true });
  };
  const openBGCList = () => {
    setOpenAddMenu({ ...openAddMenu, bgcList: true });
  };
  const openAddAdmin = () => {
    setOpenAddMenu({ ...openAddMenu, addAdmin: true });
  };
  const openAdminList = () => {
    setOpenAddMenu({ ...openAddMenu, adminList: true });
  };
  return (
    <>

      {adminLogin ? (

        <AdminDiv>
          {openAddMenu?.addProduct && <AddProducts />}
          {openAddMenu?.addProduct && <CloseDiv onClick={closeAddProductMenu} />}
          {openAddMenu?.products && <MyProductsList />}
          {openAddMenu?.products && <CloseDiv onClick={closeAddProductMenu} />}
          {openAddMenu?.changeDep && <ChangeDep />}
          {openAddMenu?.changeDep && <CloseDiv onClick={closeAddProductMenu} />}
          {openAddMenu?.changeNetwork && <ChangeNetwork />}
          {openAddMenu?.changeNetwork && <CloseDiv onClick={closeAddProductMenu} />}
          {openAddMenu?.addHome && <AddHome />}
          {openAddMenu?.addHome && <CloseDiv onClick={closeAddProductMenu} />}
          {openAddMenu?.changeHome && <MyHomeList />}
          {openAddMenu?.changeHome && <CloseDiv onClick={closeAddProductMenu} />}
          {openAddMenu?.addAbout && <AddAbout />}
          {openAddMenu?.addAbout && <CloseDiv onClick={closeAddProductMenu} />}
          {openAddMenu?.changeAbout && <MyAboutList />}
          {openAddMenu?.changeAbout && <CloseDiv onClick={closeAddProductMenu} />}
          {openAddMenu?.addBGC && <AddBGC />}
          {openAddMenu?.addBGC && <CloseDiv onClick={closeAddProductMenu} />}
          {openAddMenu?.bgcList && <MyBGCList />}
          {openAddMenu?.bgcList && <CloseDiv onClick={closeAddProductMenu} />}
          {openAddMenu?.addAdmin && <AddAdmin />}
          {openAddMenu?.addAdmin && <CloseDiv onClick={closeAddProductMenu} />}
          {openAddMenu?.adminList && <AllAdmins />}
          {openAddMenu?.adminList && <CloseDiv onClick={closeAddProductMenu} />}

          <AdminHeader>
            <AdminHeaderProfileImg src={adminImg} />
          </AdminHeader>
          <AdminBody>
            <AdminBodyDivsAll>

              <AdminBodyDivs>
                <AdminBodyDiv onClick={openAddProduct}>
                  <AdminBodyDivImgDiv>
                  <AdminBodyImg src={category} />
                  </AdminBodyDivImgDiv>
                  <AdminBodyTextDiv>
                    <AdminBodyText>Ավելացնել նկար</AdminBodyText>
                  </AdminBodyTextDiv>
                </AdminBodyDiv>
                <AdminBodyDiv onClick={openProductsMenu}>
                  <AdminBodyDivImgDiv>
                  <AdminBodyImg src={category} />
                  </AdminBodyDivImgDiv>
                  <AdminBodyTextDiv>
                    <AdminBodyText>Ամբողջ նկարներ</AdminBodyText>
                  </AdminBodyTextDiv>
                </AdminBodyDiv>
              </AdminBodyDivs>
              <AdminBodyDivs>
                <AdminBodyDiv onClick={openChangeDepMenu}>
                  <AdminBodyDivImgDiv>
                    <AdminBodyImg src={category} />
                  </AdminBodyDivImgDiv>
                  <AdminBodyTextDiv>
                    <AdminBodyText>Փոփոխել մենյուի բաժինները</AdminBodyText>
                  </AdminBodyTextDiv>
                </AdminBodyDiv>
                <AdminBodyDiv onClick={openChangeNetworkMenu}>
                  <AdminBodyDivImgDiv>
                    <AdminBodyImg src={category} />
                  </AdminBodyDivImgDiv>
                  <AdminBodyTextDiv>
                    <AdminBodyText>Փոփոխել սոց կայքերը</AdminBodyText>
                  </AdminBodyTextDiv>
                </AdminBodyDiv>

              </AdminBodyDivs>

              <AdminBodyDivs>
                <AdminBodyDiv onClick={openAddAbout}>
                  <AdminBodyDivImgDiv>
                  <AdminBodyImg src={category} />
                  </AdminBodyDivImgDiv>
                  <AdminBodyTextDiv>
                    <AdminBodyText>Ավելացնել Տեքստ մեր մասին</AdminBodyText>
                  </AdminBodyTextDiv>
                </AdminBodyDiv>
                <AdminBodyDiv onClick={openAboutChange}>
                  <AdminBodyDivImgDiv>
                  <AdminBodyImg src={category} />
                  </AdminBodyDivImgDiv>
                  <AdminBodyTextDiv>
                    <AdminBodyText>Փոփոխել մեր մասին տեքստը</AdminBodyText>
                  </AdminBodyTextDiv>
                </AdminBodyDiv>
              </AdminBodyDivs>
              <AdminBodyDivs>
                <AdminBodyDiv onClick={addHome}>
                  <AdminBodyDivImgDiv>
                    <AdminBodyImg src={category} />
                  </AdminBodyDivImgDiv>
                  <AdminBodyTextDiv>
                    <AdminBodyText>Ավելացնել գլխավոր էյի նկար</AdminBodyText>
                  </AdminBodyTextDiv>
                </AdminBodyDiv>
                <AdminBodyDiv onClick={openHomeList}>
                  <AdminBodyDivImgDiv>
                    <AdminBodyImg src={category} />
                  </AdminBodyDivImgDiv>
                  <AdminBodyTextDiv>
                    <AdminBodyText>Փոփոխել գլխավոր էջի նկարները</AdminBodyText>
                  </AdminBodyTextDiv>
                </AdminBodyDiv>

              </AdminBodyDivs>
            </AdminBodyDivsAll>





            <AdminBodyDivsAll>

              <AdminBodyDivs>
                <AdminBodyDiv onClick={openAddBGC}>
                  <AdminBodyDivImgDiv>
                  <AdminBodyImg src={category} />
                  </AdminBodyDivImgDiv>
                  <AdminBodyTextDiv>
                    <AdminBodyText>Ավելացնել ֆոնի նկար</AdminBodyText>
                  </AdminBodyTextDiv>
                </AdminBodyDiv>
                <AdminBodyDiv onClick={openBGCList}>
                  <AdminBodyDivImgDiv>
                  <AdminBodyImg src={category} />
                  </AdminBodyDivImgDiv>
                  <AdminBodyTextDiv>
                    <AdminBodyText>Տեսնել ֆոնի նկարները</AdminBodyText>
                  </AdminBodyTextDiv>
                </AdminBodyDiv>
              </AdminBodyDivs>
              <AdminBodyDivs>
                <AdminBodyDiv onClick={openAddAdmin}>
                  <AdminBodyDivImgDiv>
                    <AdminBodyImg src={category} />
                  </AdminBodyDivImgDiv>
                  <AdminBodyTextDiv>
                    <AdminBodyText>Ավելացնել ադմին</AdminBodyText>
                  </AdminBodyTextDiv>
                </AdminBodyDiv>
                <AdminBodyDiv onClick={openAdminList}>
                  <AdminBodyDivImgDiv>
                  <AdminBodyImg src={category} />
                  </AdminBodyDivImgDiv>
                  <AdminBodyTextDiv>
                    <AdminBodyText>Ադմիններ</AdminBodyText>
                  </AdminBodyTextDiv>
                </AdminBodyDiv>

              </AdminBodyDivs>

              <AdminBodyDivs>
                <AdminBodyDiv>
                  <AdminBodyDivImgDiv>
                  <AdminBodyImg src={category} />
                  </AdminBodyDivImgDiv>
                  <AdminBodyTextDiv>
                    <AdminBodyText>...</AdminBodyText>
                  </AdminBodyTextDiv>
                </AdminBodyDiv>
                <AdminBodyDiv >
                  <AdminBodyDivImgDiv>
                  <AdminBodyImg src={category} />
                  </AdminBodyDivImgDiv>
                  <AdminBodyTextDiv>
                    <AdminBodyText>...</AdminBodyText>
                  </AdminBodyTextDiv>
                </AdminBodyDiv>
              </AdminBodyDivs>
              <AdminBodyDivs>
                <AdminBodyDiv >
                  <AdminBodyDivImgDiv>
                    <AdminBodyImg src={category} />
                  </AdminBodyDivImgDiv>
                  <AdminBodyTextDiv>
                    <AdminBodyText>...</AdminBodyText>
                  </AdminBodyTextDiv>
                </AdminBodyDiv>
                <AdminBodyDiv >
                  <AdminBodyDivImgDiv>
                    <AdminBodyImg src={category} />
                  </AdminBodyDivImgDiv>
                  <AdminBodyTextDiv>
                    <AdminBodyText>...</AdminBodyText>
                  </AdminBodyTextDiv>
                </AdminBodyDiv>

              </AdminBodyDivs>
            </AdminBodyDivsAll>
          </AdminBody>
        </AdminDiv>
      ) : (
        <AdminLoginDiv>
          <AdminLoginForm>
            <h4>Панель администратора</h4>
            <form onSubmit={handleLoginSubmit(onLoginSubmit)}>
              <AdminPanelInput placeholder="Логин" {...loginRegister("login")} />
              <AdminPanelInput
                type="password"
                placeholder="Пароль"
                {...loginRegister("password")}
              />{" "}
              <br />
              <AdminPanelButton type="submit">Войти</AdminPanelButton>
            </form>
          </AdminLoginForm>
        </AdminLoginDiv>
      )}
    </>
  );
};

export { AdminPanel };
