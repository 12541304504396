import React, { useEffect, useState } from "react";
import { Activ, DepName, DepartmentDiv, Name, NameDivs, RadioDiv, RamkaDiv, SaveBut } from "./styled";
import { toast } from "react-toastify";


export const ChangeDep = () => {
    const [defaultValue, setDefaultValue] = useState()
    const [loading , setLoading] = useState(true)
    useEffect(() => {
        const fetchData = async () => {
            try {
                const req = await fetch("https://www.server.mayyanart.com/department-get")
                const res = await req.json()
                setDefaultValue(res.dep[0])
            } catch (error) {
                console.log(error);
            }
        }
        fetchData()

    }, [])
    const [radioValues, setRadioValues] = useState()
    useEffect(() => {
        const obj = [
            {name : "all" , activ :defaultValue?.all ? true : false},
            {name : "paintings" , activ : defaultValue?.paintings ? true : false},
            {name : "daily" , activ : defaultValue?.daily ? true : false},
            {name : "wall" , activ : defaultValue?.wall ? true : false},
            {name : "nft" , activ : defaultValue?.nft ? true : false},
            {name : "press" , activ : defaultValue?.press ? true : false},
        ];
        setRadioValues(obj)
    } , [defaultValue])
  





    const changeActiv = (index) => {
        setRadioValues(prevState => {
            const updatedRadioValues = [...prevState];
            updatedRadioValues[index] = {
                ...updatedRadioValues[index],
                activ: !updatedRadioValues[index].activ
            };
            return updatedRadioValues; 
        });
    };

    const fetchData = async() => {
        setLoading(false)

        const obj = {
            all : radioValues[0].activ,
            paintings : radioValues[1].activ,
            daily : radioValues[2].activ,
            wall : radioValues[3].activ,
            nft : radioValues[4].activ,
            press : radioValues[5].activ
        }
        try {
            const req = await fetch("https://www.server.mayyanart.com/department-put", {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(obj)
            });

            setLoading(true)
        } catch (error) {
            console.log(error);
        }
       
    }
    return (
        <RamkaDiv>
            <DepartmentDiv>
                {radioValues?.map((e , i) => {
                    return(
                        <NameDivs>
                           <Name>{e.name}</Name> <Activ style={{color:e.activ ? "green" : "red"}} onClick={() => changeActiv(i)}>{e.activ ? "Բաց է" : "Փակ է"}</Activ>
                        </NameDivs>
                    )
                })}
            </DepartmentDiv>
            <SaveBut onClick={fetchData}>{loading ? "Փոփոխել" : "Սպասեք"}</SaveBut>
        </RamkaDiv>
    );
};
