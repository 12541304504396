import React, { useState, useEffect } from "react";
import { CloseDiv, HoverMe, ImgDiv, Panel } from "./styled";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import inst from "../../assets/images/inst.png"
import fb from "../../assets/images/fb.png"
import { Link } from "react-router-dom"
export const LeftPanel = () => {
    const [openLeft, setOpenLeft] = useState(false);
    const [data, setData] = useState()
    useEffect(() => {
        async function myF() {
            try {
                const req = await fetch("https://www.server.mayyanart.com/network-get")
                const res = await req.json()
                setData(res.dep);
            } catch (error) {
                console.log(error);
            }
        }
        myF()
    }, [])
    console.log(data);
    return (
        <>
            <Panel $openLeft={openLeft}>
                <ImgDiv>
                    {data && Object.entries(data).map(([socialNetwork, socialArray]) => (
                        Array.isArray(socialArray) && socialArray.map((socialObject, index) => (
                            socialObject.activ && <Link to={socialObject.href} target="_blank"><img key={`${socialNetwork}-${index}`}
                                src={socialObject.src}
                                alt={`${socialNetwork}-${index}`}
                                style={{ width: (socialObject.src.includes("ANd9GcTHHImel36Ou2i8j9N1cujBjJD3eOAnFx1Udw") || (socialObject.src.includes("ANd9GcRSLqqo7eIN4iIQbGU3C1fYISY3fE0aDkmAaA")) ? "70px" : "50px") }} /></Link>
                            ))
                    ))}
                </ImgDiv>

            </Panel>
            <HoverMe onClick={() => setOpenLeft((e) => !e)} $openLeft={openLeft}>
                
                {openLeft ? <IoIosArrowBack style={{ marginTop: "5px" }} /> : <IoIosArrowForward style={{ marginTop: "5px" }} />}
            </HoverMe>
            {openLeft && <CloseDiv onClick={() => setOpenLeft((e) => !e)} />}
        </>
    )
}