import React, { useState } from "react";
import { useForm } from "react-hook-form";

import {
  RamkaDiv,
  TitleDiv,
  AddProductFormDiv,
  AddProductsInput,
  AddProductsInputCheck,
  AddImgInput,
  AddProductTextArea,
  AddProductsButton,
  AddProductsSelect,
} from "./styled"
import { toast } from "react-toastify";

export const AddBGC = () => {
  const { register: productsRegister, handleSubmit: handleProductsSubmit } = useForm();
  const [waitRequest, setWaitRequest] = useState(false)
  const [loading, setLoading] = useState(true)
  const onProductsSubmit = async (data) => {
    if (!data.img[0]) {
      return
    }
    try {
      setLoading(false)
      const formData = new FormData();
      formData.append('src', data.img[0]);
      formData.append('name', data.name);
      setWaitRequest(true)
      const req = await fetch('https://www.server.mayyanart.com/bgc-post', {
        method: 'POST',
        body: formData,
      });
      setLoading(true)
      setWaitRequest(false)
      const res = req.json()
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <RamkaDiv>

      <TitleDiv>
        <h3>Ավելացնել ֆոնի նկար </h3>
      </TitleDiv>
      <AddProductFormDiv onSubmit={handleProductsSubmit(onProductsSubmit)}>

        <AddProductsInput placeholder='name' {...productsRegister('name')} >
    <option>home</option>
    <option>contact</option>
    <option>about</option>
    <option>products</option>
    <option>product-page</option>
        </AddProductsInput>

        <AddImgInput type='file' accept='image/*'  {...productsRegister('img')} />



        <AddProductsButton disabled={!loading}>{loading ? "Ավելացնել նկար" : "սպասել..."}</AddProductsButton>
      </AddProductFormDiv>
    </RamkaDiv>
  )

}