import styled from "styled-components";


export const MainDiv = styled.div`
    min-height: 100vh;
    width: 100%;
    background-color: #646765;

`


export const GlavniImg = styled.div`
    width: 100%;
    background-color: #646765;
    padding-top: 70px;
    min-height: 100vh;
    
    img{
        width: 100%;
        height: 100vh;
        object-fit: contain;
        
    }
`


export const DetailsDiv = styled.div`
    width: 45%;
    margin: 100px auto;
    text-align: center;

    span{
        font-family: 'Lato', sans-serif;
        color: white;
        font-size: 45px;
        font-weight: 300;
    }
    @media(max-width: 1200px){
        width: 60%;
    }
    @media(max-width: 800px){
        width: 70%;
    }
    @media(max-width: 600px){
        width: 80%;
    }
    @media(max-width: 400px){
        width: 90%;
    }
    
`

export const ImgDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img{
        width:70%;
        border-radius: 20px;
        object-fit: cover;
        margin-top: 50px;

    }
`
export const ArrowsDiv = styled.div`
    width: 50px;
    height: 407px;
    padding-top: 200px;
    cursor: pointer;
`

export const HorizonDiv = styled.div`
    width: 20%;
    margin: 0 auto;
    margin-top: 100px;
    text-align: center;

    span{
        font-family: 'Lato', sans-serif;
        color: white;
        font-size: 30px;
        font-weight: 300;
    }
    img{
        width:100%;
        border-radius: 20px;
        object-fit: cover;
        margin-top: 50px;

    }
    @media(max-width: 800px){
        width: 40%;
    }
`


export const InfoDiv = styled.div`

    width: 60%;
    margin: 0 auto;
    color: white;
    margin-top: 40px;
    text-align: center;
    line-height: 40px;
    font-family: Raleway,sans-serif;


`