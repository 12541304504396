import { useEffect, useState } from "react";
import React from "react";
import {
  Departments,
  RevComp,
  DepartmentsScroll,
} from "../../components";
import { MainDiv, HomeImg , HomeImgDiv } from "./styled";
import bgc from "../../assets/images/bgc/bgc5.jpg"
export const Home = () => {
  const [img , setImg] = useState()
  useEffect(() => {
    window.scrollTo(0, 0);
      const fetchData = async () => {
        let obj = {
          name : "home"
        }
        try {
          const req = await fetch("https://www.server.mayyanart.com/bgc-get" , {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(obj)
        })
          const res = await req.json();
          setImg(res.img);
        } catch (err) {
          console.log(err);
        }
      };
  
      fetchData();
  
  
  }, [])
  console.log(img);
  return (
    <>
      <MainDiv>
        {/* <video style={{ width: "100%" }} src={Vid} autoPlay muted loop></video> */}
        <HomeImgDiv>
          {img &&  <HomeImg src={`https://www.server.mayyanart.com/uploads/${img?.src}`} width="100%"/>}
       

        </HomeImgDiv>
        <DepartmentsScroll />
      </MainDiv>
    </>
  );
};
