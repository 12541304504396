import React ,{useEffect, useState} from "react";
import {
  ContactForm,
  ImgDiv,
  MainDiv,
  BodyTitleDiv,
  NameInput,
  EmailInput,
  MessageInput,
  MyButton,
} from "./styled";
import { useTranslation } from 'react-i18next'
import bgc2 from "../../assets/images/bgc/bgc2.jpg"
import { useForm } from "react-hook-form";

import { toast } from "react-toastify";


export const Contact = () => {
const { t } = useTranslation()
const [img , setImg] = useState()
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData1 = async () => {
      let obj = {
          name: "contact"
      }
      try {
          const req = await fetch("https://www.server.mayyanart.com/bgc-get", {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(obj)
          })
          const res = await req.json();
          setImg(res.img);
      } catch (err) {
          console.log(err);
      }
  };

  fetchData1();
  } , [])
  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const req = await fetch("https://www.server.mayyanart.com/sendMessage", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      await req.json();
      if(req.ok){
        toast.success(t("messageSend"));
      } else toast.error("faild")

      resetField("name");
      resetField("lastName");
      resetField("email");
      resetField("message");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <ImgDiv>
      {img && <img src={`https://www.server.mayyanart.com/uploads/${img?.src}`} />}
        
      </ImgDiv>
      <MainDiv>
        <BodyTitleDiv>
          <span>{t("Contact")}</span>
        </BodyTitleDiv>
        <ContactForm>
          <NameInput placeholder={t("NAME") + " *"}  {...register("name", {
            required: true,
          })} />
          <NameInput placeholder={t("SURNAME") + " *"} {...register("lastName", {
            required: true,
          })} />
          <EmailInput placeholder={t("EMAIL") + " *"} {...register("email", {
            required: true,
          })} />
          <MessageInput placeholder={t("YOUR MESSAGE HERE") + " *"}  {...register("message", {
            required: true,
          })} />
          <MyButton onClick={handleSubmit(onSubmit)}
            type="submit"
            value="Send">
            {t("SEND MESSAGE")}
          </MyButton>
        </ContactForm>
      </MainDiv>
    </>












    // <div style={{position:"relative" , top:"80px"}}>
    //   <MainSection>
    //     <Container>
    //       <ContactInfo>
    //         <ContactInfoMainDiv>
    //           <ContactInfoH2>Contact Info</ContactInfoH2>
    //           <Info>
    //             <Li>
    //               <ContactInfoSpan1>
    //                 <IconImg src={LocImg}></IconImg>
    //               </ContactInfoSpan1>
    //               <ContactInfoSpan>
    //                 2912 Meadowbrook Road <br />
    //                 Los Angeles, Ca <br />
    //                 90017
    //               </ContactInfoSpan>
    //             </Li>
    //             <Li>
    //               <ContactInfoSpan1>
    //                 <IconImg src={MailImg}></IconImg>
    //               </ContactInfoSpan1>
    //               <ContactInfoSpan>lorem@ipsum.am</ContactInfoSpan>
    //             </Li>
    //             <Li>
    //               <ContactInfoSpan1>
    //                 <IconImg src={CallPng}></IconImg>
    //               </ContactInfoSpan1>
    //               <ContactInfoSpan>055-71-02-08</ContactInfoSpan>
    //             </Li>
    //           </Info>
    //         </ContactInfoMainDiv>
    //       </ContactInfo>
    //       <ContactForm>
    //         <ContactFormH2>Send a message</ContactFormH2>
    //         <FormBox>
    //           <InputBox>
    //             <Input1
    //               type="text"
    //               placeholder="First Name"
    //               {...register("name", {
    //                 required: true,
    //               })}
    //             />
    //             <ErrorMessage>{errors.name ? errors.name : null}</ErrorMessage>
    //           </InputBox>
    //           <InputBox>
    //             <Input1
    //               type="text"
    //               placeholder="Last Name"
    // {...register("lastName", {
    //   required: true,
    // })}
    //             />
    //             <ErrorMessage>
    //               {errors.lastName ? errors.lastName : null}
    //             </ErrorMessage>
    //           </InputBox>
    //           <InputBox>
    //             <Input1
    //               type="email"
    //               placeholder="Email Adress"
    //               {...register("email", {
    //                 required: true,
    //               })}
    //             />
    //             <ErrorMessage>
    //               {errors.email ? errors.email : null}
    //             </ErrorMessage>
    //           </InputBox>
    //           <InputBox>
    //             <Input1
    //               type="text"
    //               placeholder="Mobile Number"
    //               {...register("phone", {
    //                 required: true,
    //               })}
    //             />
    //             <ErrorMessage>
    //               {errors.phone ? errors.phone : null}
    //             </ErrorMessage>
    //           </InputBox>
    //           <InputBox2>
    //             <Textarea
    //               placeholder="Write your message here..."
    // {...register("message", {
    //   required: true,
    // })}
    //             ></Textarea>
    //             <ErrorMessage>
    //               {errors.message ? errors.message : null}
    //             </ErrorMessage>
    //           </InputBox2>
    //           <InputBox2>
    //             <SubmitButton
    //               onClick={handleSubmit(onSubmit)}
    //               type="submit"
    //               value="Send"
    //             />
    //           </InputBox2>
    //         </FormBox>
    //       </ContactForm>
    //     </Container>
    //   </MainSection>
    // </div>
  );
};
