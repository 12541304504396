import React, { useState } from "react";
import { useForm } from "react-hook-form";

import {
  RamkaDiv,
  TitleDiv,
  AddProductFormDiv,
  AddProductsInput,
  AddProductsInputCheck,
  AddImgInput,
  AddProductTextArea,
  AddProductsButton,
  AddProductsSelect,
} from "./styled"
import { toast } from "react-toastify";

export const AddAbout = () => {
  const { register: productsRegister, handleSubmit: handleProductsSubmit } = useForm();
  const [waitRequest, setWaitRequest] = useState(false)
  const [loading, setLoading] = useState(true)
  const onProductsSubmit = async (data) => {
  console.log(data);
    try {
      setLoading(false)
      const formData = new FormData();
      formData.append('textRU', data.textRU);
      formData.append('titleRU', data.titleRU);
      formData.append('textEN', data.textEN);
      formData.append('titleEN', data.titleEN);
      formData.append('textAM', data.textAM);
      formData.append('titleAM', data.titleAM);
      setWaitRequest(true)
      const req = await fetch('https://www.server.mayyanart.com/about-post', {
        method: 'POST',
        body: formData,
      });
      setLoading(true)
      setWaitRequest(false)
      const res = req.json()
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <RamkaDiv>

      <TitleDiv>
        <h3>Ավելացնել Տեքստ մեր մասին</h3>
      </TitleDiv>
      <AddProductFormDiv onSubmit={handleProductsSubmit(onProductsSubmit)}>

        <AddProductsInput placeholder='Տեքստի վերնագիր AM' {...productsRegister('titleAM')} />
        <AddProductsInput placeholder='Տեքստ AM' {...productsRegister('textAM')} />
        <AddProductsInput placeholder='Տեքստի վերնագիր RU' {...productsRegister('titleRU')} />
        <AddProductsInput placeholder='Տեքստ RU' {...productsRegister('textRU')} />
        <AddProductsInput placeholder='Տեքստի վերնագիր EN' {...productsRegister('titleEN')} />
        <AddProductsInput placeholder='Տեքստ EN' {...productsRegister('textEN')} />

        

        <AddProductsButton disabled={!loading}>{loading ? "Ավելացնել տեքստ" : "սպասել..."}</AddProductsButton>
      </AddProductFormDiv>
    </RamkaDiv>
  )

}