import React, { useEffect, useState, useRef } from "react";
import { useForm } from 'react-hook-form';
import { MdOutlineDoneAll } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { InStockP, DoneDiv, EditProductButton, EditImgInput, EditProductDiv, EditProductInput, ProductsDiv, ProductsInfoDiv, SortSpan, SortDiv, SearchProducts, SearchDivIconDiv } from "./styled";
import { FaEdit } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import { FaSearch } from "react-icons/fa";

export const MyHomeList = React.memo(() => {
  const { register, handleSubmit } = useForm();
  const itemsPerPage = 20;
  const [products, setProducts] = useState([]);
  const [editProductIndex, setEditProductIndex] = useState(null);
  const [sortProducts, setSortProducts] = useState("minMax");
  const [sortProductsBoolean, setSortProductsBoolean] = useState(true);
  const [visibleItems, setVisibleItems] = useState(itemsPerPage);
  const [searchProducts, setSerachProducts] = useState('')

  const containerRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const req = await fetch("https://www.server.mayyanart.com/home-get")
        const res = await req.json();
        setProducts(res.home);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();

  }, [editProductIndex]);

  const deleteProduct = async (i) => {
    try {
      const ProductsToDelete = products[i];
      const req = await fetch(`https://www.server.mayyanart.com/home/${ProductsToDelete._id}`, {
        method: "DELETE",
      });

      if (req.ok) {
        const updatedproducts = products.filter((_, index) => index !== i);
        setProducts(updatedproducts);
        setEditProductIndex(null);
      } else {
        console.error("Не удалось удалить обзор");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const changeProduct = async (data, i) => {
    try {
      const formData = new FormData();
      formData.append('textAM', data?.textAMRU?.trim() !== '' ? data.textAM : products[i].textAM);
      formData.append('textTitleAM', data?.textTitleAM?.trim() !== '' ? data.textTitleAM : products[i].textTitleAM);
      formData.append('textRU', data?.textRU?.trim() !== '' ? data.textRU : products[i].textRU);
      formData.append('textTitleRU', data?.textTitleRU?.trim() !== '' ? data.textTitleRU : products[i].textTitleRU);
      formData.append('textEN', data?.textEN?.trim() !== '' ? data.textEN : products[i].textEN);
      formData.append('textTitleEN', data?.textTitleEN?.trim() !== '' ? data.textTitleEN : products[i].textTitleEN);
      formData.append('activ', data?.activ !== '' ? data.activ : products[i].activ);
      if (data.src[0]) {
        formData.append('src', data.src[0]);
      }
      console.log(formData);
      const req = await fetch(`https://www.server.mayyanart.com/home/${products[i]._id}`, {
        method: "PUT",
        body: formData,
      });
      if (req.ok) {
        const updatedProducts = [...products];
        updatedProducts[i] = { ...updatedProducts[i], ...data, src: null };
        console.log(updatedProducts);

        setProducts(updatedProducts);
        setEditProductIndex(null);

      } else {
        console.error("Не удалось обновить продукт");

      }

    } catch (err) {
      console.log(err);
    }
  };

  const toggleEditProduct = (index) => {
    setEditProductIndex((e) => (e === index ? null : index));
  };



  const handleScroll = () => {
    const container = containerRef.current;
    if (container) {
      const isAtBottom =
        container.scrollTop + container.clientHeight >= container.scrollHeight - 20;


      if (isAtBottom) {
        setVisibleItems((prevVisibleItems) => prevVisibleItems + itemsPerPage);
      }
    }
  };

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);
  return (
    <>

      <ProductsDiv ref={containerRef}>
        <SortDiv>
          <SearchProducts placeholder='search' onChange={(e) => setSerachProducts(e.target.value)} />
          <SearchDivIconDiv>
            <FaSearch />
          </SearchDivIconDiv>
        </SortDiv>

        {products?.filter(
          (e) =>
            e.textEN?.toLowerCase().includes(searchProducts.toLowerCase()) ||
            e.textTitleEN?.toLowerCase().includes(searchProducts.toLowerCase())
        ).slice(0, visibleItems)?.map((e, i) => (
          <ProductsInfoDiv key={e._id}>
            <DoneDiv>
              <button onClick={() => toggleEditProduct(i)}>{<FaEdit />} </button>
              <span> </span>
              <button onClick={() => deleteProduct(i)}> {<RxCross1 />}</button>
            </DoneDiv>
            <p>Տեքստ AM: {e.textAM}</p>
            <p>Վերնագիր AM: {e.textTitleAM}</p>
            <p>Տեքստ RU: {e.textRU}</p>
            <p>Վերնագիր RU: {e.textTitleRU}</p>
            <p>Տեքստ EN: {e.textEN}</p>
            <p>Վերնագիր EN: {e.textTitleEN}</p>
            <p>նկարի հասցե: <a href={`https://www.server.mayyanart.com/uploads${e.src}`}>{e.src}</a></p>
            <p>Կարգավիճակ: {e.activ ? <span style={{ color: "green" }}>Ցուցադրվում է</span > : <span style={{ color: "red" }}>Չի ցուցադրվում</span>}</p>

            {editProductIndex === i && (
              <EditProductDiv onSubmit={handleSubmit((data) => changeProduct(data, i))}>
                <EditProductInput placeholder="Տեքստ AM"  {...register("textAM")} /><br />
                <EditProductInput placeholder="Վերնագիր AM"  {...register("textTitleAM")} /><br />
                <EditProductInput placeholder="Տեքստ RU"  {...register("textRU")} /><br />
                <EditProductInput placeholder="Վերնագիր RU"  {...register("textTitleRU")} /><br />
                <EditProductInput placeholder="Տեքստ EN"  {...register("textEN")} /><br />
                <EditProductInput placeholder="Վերնագիր EN"   {...register("textTitleEN")} /><br />
                <EditProductInput placeholder="Նկար" type="file"  {...register("src")} /><br />
                <label><EditProductInput placeholder="Կարգավիճակ" type="checkbox"  {...register("activ")} /> Կարգավիճակ</label><br />
                <EditProductButton>Փոփոխել</EditProductButton>
              </EditProductDiv>
            )}
            <hr />

          </ProductsInfoDiv>

        ))}

      </ProductsDiv>
    </>
  );
});
