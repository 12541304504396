import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ArrowsDiv, DetailsDiv, GlavniImg, HorizonDiv, ImgDiv, InfoDiv, MainDiv } from "./styled";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { useTranslation } from "react-i18next";
export const ProductStr = () => {
  const { id } = useParams();
  const [img, setImg] = useState()
  const [imgSlide, setImgSlide] = useState(0)
  const { t } = useTranslation()
  const [lang, setLang] = useState()
  const [bgc, setBgc] = useState()
  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const req = await fetch("https://www.server.mayyanart.com/product-id", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: id, name: "product-page" }),
      })
      const res = await req.json()
      setImg(res.user)
      setBgc(res.bgc)
    }
    fetchData()

  }, [])
  useEffect(() => {
    setLang(localStorage.getItem('lang'))
  }, [t])
  const calculateTimeLeft = () => {
    const difference = +new Date(img?.timer) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const formatTimeComponent = (timeComponent) => {
    return timeComponent < 10 ? `0${timeComponent}` : timeComponent;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval, index) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={interval}>
        {formatTimeComponent(timeLeft[interval])}
        {index < Object.keys(timeLeft).length - 1 ? ":" : ""}
      </span>
    );
  });
  const slideLeft = () => {
    if (imgSlide === 0) {
      setImgSlide(img.imgs.length - 1)
    } else setImgSlide((e) => --e)
  }
  const slideRight = () => {
    if (imgSlide === img.imgs.length - 1) {
      setImgSlide(0)
    } else setImgSlide((e) => ++e)
  }
  console.log(bgc);
  return (
    <MainDiv>
      <GlavniImg>
        {img ?
          (img?.bg ?
            <img src={`https://www.server.mayyanart.com/uploads/${img?.imgs[0]}`} /> :
            <img src={`https://www.server.mayyanart.com/uploads/${bgc?.src}`} />
          ) :
          null
        }


      </GlavniImg>
      <DetailsDiv>
        <span>DETAILS</span>
        <ImgDiv>
          <ArrowsDiv onClick={slideLeft}><span><IoIosArrowBack /></span></ArrowsDiv>
          {img?.imgs.map((e, i) => {
            if (imgSlide === i) {
              return (
                <img src={`https://www.server.mayyanart.com/uploads/${img?.imgs[i]}`} />
              )
            }
          })}
          <ArrowsDiv onClick={slideRight}><span><IoIosArrowForward /></span></ArrowsDiv>

        </ImgDiv>

      </DetailsDiv>
      <HorizonDiv>
        <span>HORIZON</span>
        <img src={`https://www.server.mayyanart.com/uploads/${img?.imgs[0]}`} />

      </HorizonDiv>
      <InfoDiv>
        <p><span>{(lang === "ru" && img?.descriptionRU) || (lang === "en" && img?.descriptionEN) || (lang === "am" && img?.descriptionAM)}</span></p>
        <p><span>{(lang === "ru" && img?.yuxanerkRU) || (lang === "en" && img?.yuxanerkEN) || (lang === "am" && img?.yuxanerkAM)}</span></p>
        <p><span>{(lang === "ru" && img?.ktavRU) || (lang === "en" && img?.ktavEN) || (lang === "am" && img?.ktavAM)}</span></p>
        <p>  <span>{img?.size}</span></p>
        {timerComponents.length ? timerComponents : <span>Time's up!</span>}
      </InfoDiv>
    </MainDiv>
  )
}