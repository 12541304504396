import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { TbLock } from "react-icons/tb";
import { FaEarthEurope } from "react-icons/fa6";
import {
  HeaderLanguage,
  HoverMenuLinks,
  HoverMenuPages,
  HoverOpenMenu,
  IconsDiv,
  LangImg,
  LangText,
  LanguageDiv,
  MainMenuDiv,
  MenuLinks,
  MenuLinksResp,
  MenuPages,
  MenuTwoParts,
  NameOfAPerson,
  PagesDiv,
  ProfOfAPerson,
  RespMenuDiv,
  RespMenuIcon,
} from "./styled";
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa6";
import { IoIosArrowDown } from "react-icons/io";
import i18n from "i18next"
import { useTranslation } from 'react-i18next'
import am from "../../assets/images/lang/am.png"
import en from "../../assets/images/lang/en.png"
import ru from "../../assets/images/lang/ru.png"
import { MdOutlineMenu } from "react-icons/md";
export const Menu = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("lang") || "en"
  )
  const { t } = useTranslation()
  useEffect(() => {
    if (!localStorage.getItem("lang")) {
      localStorage.setItem("lang", "en")
    }
  }, [])
  const [value, setValue] = useState(true)
  let [openLang, setOpenLang] = useState(false)
  const [selectLangImg, setSelectLangImg] = useState(
    localStorage.getItem("src") || ru
  )
  const handleSelectLang = (code, src) => {
    setSelectedLanguage(code);
    localStorage.setItem("lang", code);
    localStorage.setItem("src", src);
    i18n.changeLanguage(code)
    setOpenLang(!openLang)
    setSelectLangImg(src)
    setOpenLang(!openLang)
  }
  const languages = [
    { code: "am", name: "AM", src: `${am}` },
    { code: "ru", name: "RU", src: `${ru}` },
    { code: "en", name: "EN", src: `${en}` },
  ]
  const [hoverMenu, setHoverMenu] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [pages, setPages] = useState();
  const [pagesResp, setPagesResp] = useState();
  const [openResMenu, setOpenResMenu] = useState(false)
  const [openRespHoverMenu, setOpenRespHoverMenu] = useState(false)
  const [data, setData] = useState()
  const [dep, setDep] = useState()
  const hoverMenuPages = [
    { pageName: t("All products"), path: "/products", activ: dep?.all ? true : false },
    { pageName: "Paintings", path: "/paintings", activ: dep?.paintings ? true : false },
    { pageName: "Daily scathes", path: "/daily-scathes", activ: dep?.daily ? true : false },
    { pageName: "Wall art", path: "/wall-art", activ: dep?.wall ? true : false },
    { pageName: "NFT", path: "/nft", activ: dep?.nft ? true : false },
  ];
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 500);
  useEffect(() => {
    if (isSmallScreen && pages) {
      let newArray = [...pages.slice(0, 1), ...pages.slice(2), pages[1]];

      setPagesResp(newArray)
    }
  }, [isSmallScreen, pages])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const req = await fetch("https://www.server.mayyanart.com/network-get")
        const res = await req.json()
        setData(res.dep)
      } catch (error) {
        console.log(error);
      }
    }
    fetchData()

  }, [])
  const loc = useLocation();
  useEffect(() => {
    const obj = [
      {
        pageName: t('Home'),
        path: "/",
        activ: loc.pathname === "/" ? true : false,
        show: true
      },
      {
        pageName: <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          {t("Art")}  <IoIosArrowDown color="white" />
        </div>,

        hover: true,
        activ: loc.pathname === "/art" ? true : false,
        show: true

      },
      {
        pageName: "Press",
        path: "/",
        activ: false,
        show: dep?.press ? true : false

      },
      {
        pageName: t("About"),
        path: "/about",
        activ: loc.pathname === "/about" ? true : false,
        show: true

      },
      {
        pageName: t("Contact"),
        path: "/contact",
        activ: loc.pathname === "/contact" ? true : false,
        show: true

      },


    ];

    setPages(obj);
    const scrollTop = window.scrollY;
    const threshold = 70;


    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const threshold = 70;
      if (openResMenu) {
        return;
      }
      if (scrollTop > threshold) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loc, openResMenu, selectedLanguage, dep]);
  const openHoverMenu = () => {
    setHoverMenu(true);

  };
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 801);
    };
    handleResize();

    window.addEventListener('resize', handleResize);
    const fetchData = async () => {
      try {
        const req = await fetch("https://www.server.mayyanart.com/department-get")
        const res = await req.json()
        setDep(res.dep[0])
      } catch (error) {
        console.log(error);
      }
    }
    fetchData()
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);
  const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 801);
  };

  const closeHoverMenu = () => {
    setHoverMenu(false);
  };
  const handleOpenResMenu = () => {
    setOpenResMenu((e) => !e)
    setOpenLang(false)
    const scrollTop = window.scrollY;
    const threshold = 70;
    if (scrollTop === 0) {
      setScrolled((e) => !e);
    }

  }
  const closeRespMenu = () => {
    setOpenResMenu(false)
    setScrolled(false)
  }
  const openResHoverMenu = () => {
    setOpenRespHoverMenu((e) => !e)
  }
  return (
    <>
      <RespMenuIcon>
        <MdOutlineMenu onClick={handleOpenResMenu} />
      </RespMenuIcon>
      <MainMenuDiv $scrolled={scrolled}>
        <MenuTwoParts style={{ width: "30%" }}>
          <NameOfAPerson $scrolled={scrolled} to="/" onClick={() => setOpenResMenu(false)}>MayYan</NameOfAPerson>
        </MenuTwoParts>
        <MenuTwoParts style={{ width: "60%", color: "#101a50" }}>
          {pages?.map((e, i) => {
            if (e.show) {

              return (
                <MenuPages $scrolled={scrolled} key={e + i} $activ={e.activ}>
                  <MenuLinks
                    onMouseOver={e.hover ? openHoverMenu : null}
                    to={e.path}
                    $scrolled={scrolled}
                  >
                    {e.pageName}
                    {e.hover && <HoverOpenMenu open={hoverMenu}
                      onMouseOver={openHoverMenu}
                      onMouseOut={closeHoverMenu}
                    >
                      <PagesDiv open={scrolled}>

                        {hoverMenuPages.map((e, i) => {
                          const isFirst = i === 0;
                          const isLast = i === hoverMenuPages.length - 1;
                          if (e.activ) {
                            return (
                              <HoverMenuPages
                                onMouseOut={closeHoverMenu}
                                onMouseOver={openHoverMenu}
                                to={e.activ ? e.path : null}
                                key={e + i}
                                open={scrolled}
                              >
                                <HoverMenuLinks
                                  onClick={closeHoverMenu}
                                  onMouseOver={openHoverMenu}
                                  open={scrolled}
                                  style={{ textDecoration: !e.activ && "none", cursor: !e.activ ? "not-allowed" : "pointer" }}
                                >
                                  {e.pageName}{!e.activ && <TbLock />}
                                </HoverMenuLinks>
                              </HoverMenuPages>
                            );

                          }

                        }
                        )}

                      </PagesDiv>

                    </HoverOpenMenu>}
                  </MenuLinks>

                </MenuPages>

              );

            }

          })}

        </MenuTwoParts>
        <IconsDiv>
          <div onClick={() => setOpenLang((e) => !e)} style={{ cursor: "pointer" }}>{selectedLanguage.toUpperCase()}</div>
          {openLang && (<LanguageDiv scrolled={scrolled}>
            {languages.map((element) => {
              return <HeaderLanguage
                key={element.code}
                onClick={
                  () => handleSelectLang(element.code, element.src)
                }
              ><LangImg src={element.src} value={element.src} />
              </HeaderLanguage>
            })}
          </LanguageDiv>
          )

          }
          {data?.fb[0].activ && <Link to={data?.fb[0].href} target="_blank"> <FaFacebookF /></Link>}
          {data?.inst[0].activ && <Link to={data?.inst[0].href} target="_blank"><FaInstagram /></Link>}
        </IconsDiv>
      </MainMenuDiv>
      <div style={{ width: "100%", height: "140px", position: "absolute" }}>

      </div>
      <RespMenuDiv $openResMenu={openResMenu}>

        {pagesResp?.map((e, i) => {
          if (e.show) {

            return (
              <div key={e + i}>   <MenuLinksResp
                to={e.hover ? null : e.path}
                onClick={e.hover ? openResHoverMenu : closeRespMenu}
                $openResMenu={openResMenu}
                key={e + i}
                style={{ textDecoration: !e.activ && "none", }}
              >
                <span> {e.pageName}</span>

              </MenuLinksResp>
                {e.hover && openRespHoverMenu && hoverMenuPages.map((element, index) => {
                  if (element.activ) {

                    return (
                      <MenuLinksResp
                        style={{ transition: "1s", height: openRespHoverMenu ? "60px" : "0px", opacity: openRespHoverMenu ? "1" : "0", textDecoration: !element.activ && "none", }}
                        to={index !== 0 && element.hover || !element.activ ? null : element.path}
                        onClick={element.hover ? null : closeRespMenu}
                        $openResMenu={openResMenu}
                        key={element + index}>
                        {element.pageName}<span>{!element.activ && <TbLock />}</span>

                      </MenuLinksResp>



                    )
                  }

                })}
              </div>




            )
          }
        })}

      </RespMenuDiv>

    </>
  );
};
