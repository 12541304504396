import React, { useState } from "react";
import { useForm } from "react-hook-form";

import {
  RamkaDiv,
  TitleDiv,
  AddProductFormDiv,
  AddProductsInput,
  AddProductsInputCheck,
  AddImgInput,
  AddProductTextArea,
  AddProductsButton,
  AddProductsSelect,
} from "./styled"
import { toast } from "react-toastify";

export const AddProducts = () => {
  const { register: productsRegister, handleSubmit: handleProductsSubmit } = useForm();
  const [waitRequest, setWaitRequest] = useState(false)
  const [loading, setLoading] = useState(true)
  const onProductsSubmit = async (data) => {
    if (
      data.titleRU === "" ||
      data.titleEN === "" ||
      data.titleAM === "" ||
      data.descriptionRU === "" ||
      data.descriptionEN === "" ||
      data.descriptionAM === "" ||
      data.size === ""
    ) {
      return toast.warning('Заполните все поля!');
    }
    if (!data.img[0]) {
      return toast.warning("Картинка не выбран!")

    }


    try {
      setLoading(false)

      const formData = new FormData();
      formData.append('titleRU', data.titleRU);
      formData.append('titleEN', data.titleEN);
      formData.append('titleAM', data.titleAM);
      if (data.img instanceof FileList) {
        for (let i = 0; i < data.img.length; i++) {
          const image = data.img[i];
          formData.append('img', image);
        }
      } else {
        formData.append('img', data.img);
      }
      formData.append('size', data.size);
      formData.append('timer', data.timer);
      formData.append('descriptionRU', data.descriptionRU);
      formData.append('descriptionEN', data.descriptionEN);
      formData.append('descriptionAM', data.descriptionAM);
      formData.append('yuxanerkRU', data.yuxanerkRU);
      formData.append('yuxanerkEN', data.yuxanerkEN);
      formData.append('yuxanerkAM', data.yuxanerkAM);
      formData.append('ktavRU', data.ktavRU);
      formData.append('ktavEN', data.ktavEN);
      formData.append('ktavAM', data.ktavAM);
      formData.append('department', data.department);
      setWaitRequest(true)
      const req = await fetch('https://www.server.mayyanart.com/products', {
        method: 'POST',
        body: formData,
      });
      setLoading(true)

      setWaitRequest(false)
      if (req.ok) {
        toast.success('Товар кспешно добавлен!')
      } else toast.error('Что-то пошел не так')


      const res = req.json()
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <RamkaDiv>

      <TitleDiv>
        <h3>Добавить продукт</h3>
      </TitleDiv>
      <AddProductFormDiv onSubmit={handleProductsSubmit(onProductsSubmit)}>

        <AddProductsInput placeholder='վերնագիր RU' {...productsRegister('titleRU')} />
        <AddProductsInput placeholder='վերնագիր EN' {...productsRegister('titleEN')} />
        <AddProductsInput placeholder='վերնագիր AM' {...productsRegister('titleAM')} />

        <AddImgInput type='file' multiple accept='image/*'  {...productsRegister('img')} />

        <AddProductsInput placeholder='Նկարի չափս 1000x500'  {...productsRegister('size')} />
        <AddProductsInput placeholder='Պատրաստ կլինի 2024-02-17`'  {...productsRegister('timer')} />
        <AddProductsInput placeholder='Կտավ RU`'  {...productsRegister('ktavRU')} />
        <AddProductsInput placeholder='Կտավ EN`'  {...productsRegister('ktavEN')} />
        <AddProductsInput placeholder='Կտավ AM`'  {...productsRegister('ktavAM')} />
        <AddProductsInput placeholder='Յուղաներկ RU'  {...productsRegister('yuxanerkRU')} />
        <AddProductsInput placeholder='Յուղաներկ EN'  {...productsRegister('yuxanerkEN')} />
        <AddProductsInput placeholder='Յուղաներկ AM'  {...productsRegister('yuxanerkAM')} />

        <AddProductTextArea placeholder='Բովանդակություն RU'  {...productsRegister('descriptionRU')} />
        <AddProductTextArea placeholder='Բովանդակություն EN'  {...productsRegister('descriptionEN')} />
        <AddProductTextArea placeholder='Բովանդակություն AM'  {...productsRegister('descriptionAM')} />
        <AddProductsSelect {...productsRegister('department')}>
          <option>
            Paintings
          </option>
          <option>
            Daily scathes
          </option>
          <option>
            Wall art
          </option>
          <option>
            NFT
          </option>



        </AddProductsSelect>

        <AddProductsButton disabled={!loading}>{loading ? "Добавить товар" : "loading..."}</AddProductsButton>
      </AddProductFormDiv>
    </RamkaDiv>
  )

}